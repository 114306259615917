import type { SwiperContainer } from 'swiper/element';

const initSwiper = async (swiperEl: SwiperContainer): Promise<void> => {
    // Disable loop mode for fewer slides
    if (swiperEl.querySelectorAll('swiper-slide').length <= 3) {
        swiperEl.removeAttribute('loop')
    }

    // Initialize Swiper instance
    Object.assign(swiperEl, {
        injectStyles: false,
        injectStylesUrls: []
    })

    // Wait for Swiper initialization
    await customElements.whenDefined('swiper-container')
    await swiperEl.initialize()

    // Mark non-visible slides as inert
    const updateInerts = (): void => {
        swiperEl.swiper.slides.forEach((slide: HTMLElement) => {
            if (slide.classList.contains('swiper-slide-visible'))
                slide.removeAttribute('inert')
            else
                slide.setAttribute('inert', '')
        })
    }

    swiperEl.setAttribute('watch-slides-progress', 'true')
    swiperEl.setAttribute('slide-visible-class', 'swiper-slide-visible')
    swiperEl.swiper.on('transitionEnd', updateInerts)
    swiperEl.swiper.once('progress', updateInerts)

    // Control pause button
    const autoplayButton = swiperEl.querySelector('[data-swiper-pause]')
    if (autoplayButton instanceof HTMLElement) {
        swiperEl.swiper.on('autoplayStart', () => {
            autoplayButton.setAttribute('aria-pressed', 'false')
        })
        swiperEl.swiper.on('autoplayStop', () => {
            autoplayButton.setAttribute('aria-pressed', 'true')
        })
        autoplayButton.addEventListener('click', () => {
            swiperEl.swiper.autoplay[swiperEl.swiper.autoplay.running ? 'stop' : 'start']()
        })
    }
}

const swipers = document.getElementsByTagName('swiper-container')
if (swipers.length) {
    // Import and register Swiper when needed
    import('@/lib/swiper')
        .then(({ register }) => register())
        .then(() => {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', () => {
                    Array.from(swipers).forEach((el) => initSwiper(el as unknown as SwiperContainer))
                })
            } else {
                Array.from(swipers).forEach((el) => initSwiper(el as unknown as SwiperContainer))
            }
        })
}