import '@/assets/main.css'
import.meta.glob('@/modules/*.{js,ts}', { eager: true }) // things too small or TTI-dependent for entire Vue components
import { createApp } from 'vue'
import { VueQueryPlugin } from '@tanstack/vue-query'

// Create top-level Vue & Svelte components
const components = import.meta.glob('@/components/*.{js,ts,vue,svelte}', { import: 'default' })
Object.entries(components).forEach(async ([path, loadComponent]) => {
    const [, name, ext] = path.match(/([^/]+?)(\..+)$/) ?? []
    const els = document.querySelectorAll(`[data-${name}]`)
    if (!els.length) return
    for (const el of els) {
        const rawProps = el.getAttribute(`data-${name}`)
        const props = rawProps ? JSON.parse(rawProps) : {}

        if (ext.endsWith('.svelte')) {
            const Component = /** @type {typeof import('svelte').SvelteComponent} */ (await loadComponent())
            new Component({
                target: el,
                props,
                hydrate: true
            })
        } else {
            const Component = /** @type {import('vue').Component} */ (await loadComponent())
            createApp(Component, rawProps ? JSON.parse(rawProps) : null)
                .use(VueQueryPlugin)
                .mount(el)
        }
    }
})